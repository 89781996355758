

















import { SearchActions } from '@/store/search/actions.search';
import { SearchGetter } from '@/store/search/getter.search';
import { SearchMutations } from '@/store/search/mutations.search';
import AppliedFilters from '@/components/common/AppliedFilters.vue';
import SearchResultTile from '@/components/search/SearchResultTile.vue';
import IntegrationsFilterSheet from '@/components/search/IntegrationsFilterSheet.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ActionMethod, MutationMethod } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';
import { IntegrationsFilters } from '@/store/search/search';

@Component({ components: { IntegrationsFilterSheet, SearchResultTile, AppliedFilters } })
export default class Search extends Vue {
  name = 'Search';

  mounted(): void {
    //@ts-ignore
    this.$gtag.pageview('/search');
  }

  @Action(`search/${SearchActions.FETCH_SEARCH}`)
  performSearch!: ActionMethod;

  @Getter(`search/${SearchGetter.GET_SEARCHTERM}`)
  searchTerm!: string;
  @Getter(`search/${SearchGetter.GET_FILTERS}`)
  filters!: IntegrationsFilters;
  @Getter(`search/${SearchGetter.IS_LOADING}`)
  isLoading!: boolean;
  @Getter(`search/${SearchGetter.GET_ITEMS_PER_PAGE}`)
  itemsPerPage!: number;

  @Mutation(`search/${SearchMutations.SET_PAGE}`)
  setPage!: MutationMethod;

  doSearch(isPrevious: boolean) {
    this.performSearch({ isPrevious: isPrevious ? isPrevious : false });
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('search', {
      event_category: 'integrations',
      event_label: 'Search From Home',
      value: this.filters,
    });
  }
}
