import i18n from '@/plugins/i18n';
import { DogStatus, Os } from '@/generated/graphql';
import { TranslateResult } from 'vue-i18n';

export function getOsArray(): { code: Os | null; name: TranslateResult }[] {
  return [
    { code: null, name: i18n.t(`osNames.notSelected`) },
    { code: Os.Android, name: i18n.t(`osNames.${Os.Android}`) },
    { code: Os.Ios, name: i18n.t(`osNames.${Os.Ios}`) },
    { code: Os.Browser, name: i18n.t(`osNames.${Os.Browser}`) },
    { code: Os.Cloud, name: i18n.t(`osNames.${Os.Cloud}`) },
  ];
}

export function getStatusArray(): { code: DogStatus | null; name: TranslateResult }[] {
  return [
    { code: DogStatus.Active, name: i18n.t(`intStatus.${DogStatus.Active}`) },
    { code: DogStatus.Inactive, name: i18n.t(`intStatus.${DogStatus.Inactive}`) },
  ];
}
