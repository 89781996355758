


















import { ROUTER } from '@/constants';
import { Integration } from '@/generated/graphql';
import { CoreMutations } from '@/store/core/mutations.core';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { Mutation } from 'vuex-class';

@Component
export default class SearchResultTile extends Vue {
  name = 'SearchResultTile';

  @Prop()
  element!: Integration;

  @Mutation(`core/${CoreMutations.SET_NODE}`)
  setElement!: MutationMethod;

  openDetail() {
    this.setElement(this.element);
    this.$router
      .push({
        name: ROUTER.WORKFLOW_LIST,
        params: { integrationId: this.element.id.toString() },
      })
      .catch(err => console.error(err));
  }
}
