




















import { TreeNode } from '@/generated/graphql';
import Vue from 'vue';
import IntegrationsFilterSheet from '@/components/search/IntegrationsFilterSheet.vue';
import { Component } from 'vue-property-decorator';
import { ActionMethod, MutationMethod } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';
import AppliedFilters from '@/components/common/AppliedFilters.vue';
import SearchResultsTable from '@/components/search/SearchResultsTable.vue';
import { SearchGetter } from '@/store/search/getter.search';
import { SearchMutations } from '@/store/search/mutations.search';
import { SearchActions } from '@/store/search/actions.search';
import { IntegrationsFilters } from '@/store/search/search';
import { Country, Os, Product, PageInfo, DogStatus } from '@/generated/graphql';
import NiPage from '@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue';

@Component({ components: { NiPage, IntegrationsFilterSheet, AppliedFilters, SearchResultsTable } })
export default class SearchResult extends Vue {
  @Getter(`search/${SearchGetter.GET_SEARCHTERM}`)
  _getSearchTerm!: string;
  @Getter(`search/${SearchGetter.GET_SEARCH_RESULT}`)
  results!: TreeNode[];
  @Getter(`search/${SearchGetter.GET_PAGE}`)
  page!: number;
  @Getter(`search/${SearchGetter.GET_PAGE_INFO}`)
  pageInfo!: PageInfo;
  @Getter(`search/${SearchGetter.GET_TOTAL_COUNT}`)
  totalCount!: number;
  @Getter(`search/${SearchGetter.GET_ITEMS_PER_PAGE}`)
  itemsPerPage!: number;
  @Getter(`search/${SearchGetter.IS_LOADING}`)
  isLoading!: boolean;
  @Getter(`search/${SearchGetter.GET_FILTERS}`)
  _getFilters!: IntegrationsFilters;

  @Mutation(`search/${SearchMutations.RESET_SEARCH}`)
  resetSearch!: MutationMethod;

  @Mutation(`search/${SearchMutations.SET_SEARCHTERM}`)
  _setSearchTerm!: MutationMethod;

  @Mutation(`search/${SearchMutations.SET_FILTERS}`)
  _setFilters!: MutationMethod;

  @Action(`search/${SearchActions.FETCH_SEARCH}`)
  performSearch!: ActionMethod;

  get searchTerm(): string {
    return this._getSearchTerm;
  }

  set searchTerm(v: string) {
    this._setSearchTerm(v);
  }

  get filters(): IntegrationsFilters {
    return this._getFilters;
  }

  set filters(filters: IntegrationsFilters) {
    this._setFilters(filters);
  }

  doSearch(isPrevious: boolean) {
    this.performSearch({ isPrevious: isPrevious ? isPrevious : false });
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('search', {
      event_category: 'integrations',
      event_label: 'Search From Table',
      value: this.filters,
    });
  }

  created() {
    if (this.$route.query) {
      this.resetSearch();
      this.searchTerm = this.$route.query.searchTerm as string;
      this.filters = {
        operatingSystem: this.$route.query.operatingSystem as Os,
        region: this.$route.query.region as Country['code'],
        product: this.$route.query.product as Product,
        deviceTypeGroupId: parseInt(this.$route.query.deviceTypeGroupId as string),
        status: this.$route.query.status as DogStatus,
      };
      this.doSearch(false);
    }
  }
}
