
































import Vue from 'vue';
import { DeviceTypeGroup, FetchDeviceTypeGroupsQuery, FetchDeviceTypeGroupsQueryVariables } from '@/generated/graphql';

import { Component, Prop, Watch } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';
import { IntegrationsFilters } from '@/store/search/search';
import { SearchMutations } from '@/store/search/mutations.search';
import { SearchGetter } from '@/store/search/getter.search';
import { SearchActions } from '@/store/search/actions.search';
import { ActionMethod } from '@/helpers/typeHelpers';

@Component({ components: {}, props: {} })
export default class AppliedFilters extends Vue {
  deviceGroupName = '';
  @Prop({ default: null })
  filters!: IntegrationsFilters;

  @Prop({ default: () => '' })
  searchStr!: string;

  @Prop({ default: () => true })
  changeRoute!: boolean;

  @Prop({ default: () => false })
  updateOnClose!: boolean;

  @Getter(`search/${SearchGetter.GET_DEVICE_TYPE_GROUPS}`)
  deviceTypeGroups!: DeviceTypeGroup[];

  @Mutation(`search/${SearchMutations.RESET_SEARCH}`)
  resetSearch!: MutationMethod;

  @Action(`search/${SearchActions.FETCH_DEVICE_TYPE_GROUPS}`)
  performFetchDeviceTypes!: ActionMethod<FetchDeviceTypeGroupsQueryVariables, FetchDeviceTypeGroupsQuery>;

  get hasNoFilters(): boolean {
    return Object.keys(this.filters).every(key => !this.filters[key as keyof IntegrationsFilters]);
  }

  clearFilter(key: string, updateOnClose: boolean) {
    this.filters[key as keyof IntegrationsFilters] = null;
    if (!updateOnClose) return;
  }

  @Watch('filters.deviceTypeGroupId', { deep: true })
  onPropertyChanged() {
    if (this.deviceTypeGroups.length > 0) {
      this.updateDeviceTypeGroupName();
    }
  }

  updateDeviceTypeGroupName() {
    const group = this.deviceTypeGroups.find(x => x.id === this.filters.deviceTypeGroupId);
    this.deviceGroupName = group != undefined ? group.name : `Group ID: ${this.filters.deviceTypeGroupId}`;
  }

  async mounted() {
    if (this.deviceTypeGroups.length === 0) {
      await this.performFetchDeviceTypes({ name: '' });
    }
    this.updateDeviceTypeGroupName();
  }
}
