import i18n from '@/plugins/i18n';
import { Product } from '@/generated/graphql';
import { TranslateResult } from 'vue-i18n';

export function getProductArray(): { code: Product; name: TranslateResult }[] {
  return [
    { code: Product.DcrStatic, name: i18n.t(`products.${Product.DcrStatic}`) },
    { code: Product.DcrVideo, name: i18n.t(`products.${Product.DcrVideo}`) },
    { code: Product.Dtvr, name: i18n.t(`products.${Product.Dtvr}`) },
    { code: Product.Dar, name: i18n.t(`products.${Product.Dar}`) },
    { code: Product.Da, name: i18n.t(`products.${Product.Da}`) },
  ];
}
