






















































































import Vue from 'vue';
import { MutationMethod } from 'vuex';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { SearchGetter } from '@/store/search/getter.search';
import { CoreGetter } from '@/store/core/getter.core';
import { CoreActions } from '@/store/core/actions.core';
import { IntegrationsFilters } from '@/store/search/search';
import {
  Country,
  DeviceTypeGroup,
  FetchDeviceTypeGroupsQuery,
  FetchDeviceTypeGroupsQueryVariables,
  Product,
} from '@/generated/graphql';
import { Getter, Action, Mutation } from 'vuex-class';
import { ROUTER } from '@/constants';
import { filtersToQueryString } from '@/helpers/routeHelper';
import { SearchMutations } from '@/store/search/mutations.search';
import { getOsArray, getStatusArray } from '@/helpers/osHelper';
import { getProductArray } from '@/helpers/productHelper';
import { ActionMethod } from '@/helpers/typeHelpers';
import { SearchActions } from '@/store/search/actions.search';

@Component({ components: {}, props: {}, methods: { getOsArray, getProductArray, getStatusArray } })
export default class IntegrationsFilterSheet extends Vue {
  @Prop({ default: () => true })
  changeRoute!: boolean;
  @Prop({ default: () => false })
  updateOnSelect!: boolean;

  filterSheet = false;
  // because jest can't mock/read $t
  data() {
    return {
      searchCountry: '',
      filterSheet: false,
      osTypes: getOsArray(),
      deviceTypeGroup: -1,
      products: [
        { code: Product.DcrStatic, name: this.$t(`products.${Product.DcrStatic}`) },
        { code: Product.DcrVideo, name: this.$t(`products.${Product.DcrVideo}`) },
        { code: Product.Dtvr, name: this.$t(`products.${Product.Dtvr}`) },
      ],
    };
  }

  mounted() {
    this.performFetchCountries();
    if (this.deviceTypeGroups.length === 0) {
      this.performFetchDeviceTypes({ name: '' });
    }
  }

  @Getter(`search/${SearchGetter.GET_FILTERS}`)
  filters!: IntegrationsFilters;
  @Getter(`search/${SearchGetter.GET_SEARCHTERM}`)
  searchTerm!: string;
  @Getter(`search/${SearchGetter.GET_ITEMS_PER_PAGE}`)
  itemsPerPage!: number;
  @Getter(`core/${CoreGetter.GET_COUNTRIES}`)
  countries!: Country[];
  @Getter(`search/${SearchGetter.GET_DEVICE_TYPE_GROUPS}`)
  deviceTypeGroups!: DeviceTypeGroup[];

  @Mutation(`search/${SearchMutations.SET_FILTERS_OS}`)
  setFilterOs!: MutationMethod;
  @Mutation(`search/${SearchMutations.SET_FILTERS_DEVICE_TYPE_GROUP}`)
  setFilterDeviceTypeGroup!: MutationMethod;
  @Mutation(`search/${SearchMutations.SET_FILTERS_COUNTRY}`)
  setFilterCountry!: MutationMethod;
  @Mutation(`search/${SearchMutations.SET_FILTERS_PRODUCT}`)
  setFilterProduct!: MutationMethod;
  @Mutation(`search/${SearchMutations.SET_FILTERS_STATUS}`)
  setFilterStatus!: MutationMethod;
  @Mutation(`search/${SearchMutations.SET_PAGE}`)
  setPage!: MutationMethod;
  @Mutation(`search/${SearchMutations.RESET_SEARCH}`)
  resetSearch!: MutationMethod;

  @Action(`core/${CoreActions.FETCH_COUNTRIES}`)
  performFetchCountries!: ActionMethod<unknown, unknown>;
  @Action(`search/${SearchActions.FETCH_DEVICE_TYPE_GROUPS}`)
  performFetchDeviceTypes!: ActionMethod<FetchDeviceTypeGroupsQueryVariables, FetchDeviceTypeGroupsQuery>;

  @Watch('filters', { deep: true })
  onPropertyChanged() {
    this.resetSearch();
    if (this.changeRoute) {
      const queryFilters = filtersToQueryString(this.filters);

      this.$router
        .push({
          name: ROUTER.SEARCH_RESULT,
          query: {
            searchTerm: this.searchTerm as string,
            ...queryFilters,
          },
        })
        .catch(err => console.error(err));

      this.$emit('do-search');

      // @ts-ignore
      /* eslint-disable @typescript-eslint/camelcase */
      this.$gtag.event('filter', {
        event_category: 'integrations',
        event_label: 'filter integrations',
        value: this.filters,
      });
    }
  }

  openSheet() {
    this.filterSheet = true;
  }
}
