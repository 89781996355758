


















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ROUTER } from '@/constants';
import { Getter, Mutation } from 'vuex-class';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import { MutationMethod } from 'vuex';
import { SearchGetter } from '@/store/search/getter.search';
import { SearchMutations } from '@/store/search/mutations.search';
import { Workflow, PageInfo } from '@/generated/graphql';
import { ReducedWorkflow } from '@/store/workflow/workflow';
import { Breakpoint } from 'vuetify/types/services/breakpoint';
import { ComputedHeaders } from '@/store/search/search';

@Component({ components: {}, methods: { getCssClassFromState } })
export default class SearchResultsTable extends Vue {
  headers = [
    { text: 'Name', value: 'name', align: 'start', sortable: false },
    { text: 'App ID', value: 'appId', align: 'start', sortable: false, hide: 'lgAndUp' },
    { text: 'Country', value: 'country', align: 'start', sortable: false, hide: 'lgAndUp' },
    { text: 'OS', value: 'os', align: 'start', sortable: false, hide: 'lgAndUp' },
    { text: 'Parent', value: 'parentName', align: 'start', sortable: false },
    { text: 'Client ID', value: 'clientId', align: 'start', sortable: false },
    { text: 'C6', align: 'start', sortable: false, value: 'c6' },
    { text: 'Certifications', value: 'certifications', align: 'start', sortable: false },
  ];

  @Getter(`search/${SearchGetter.GET_ITEMS_PER_PAGE}`)
  itemsPerPage!: number;

  @Getter(`search/${SearchGetter.GET_PAGE}`)
  page!: number;

  @Mutation(`search/${SearchMutations.SET_PAGE}`)
  setPage!: MutationMethod;

  @Mutation(`search/${SearchMutations.SET_ITEMS_PER_PAGE}`)
  setItemsPerPage!: MutationMethod;

  @Prop({ default: () => [] }) results!: string[];
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => ({}) }) pageInfo!: PageInfo;
  @Prop({ default: () => 0 }) totalCount!: number;

  updatePage(pageNumber: number) {
    if (this.loading) return;
    if (this.page === pageNumber) return;
    let isPrevious = false;
    if (pageNumber < this.page) {
      isPrevious = true;
    }

    this.setPage(pageNumber);
    this.$emit('do-search', isPrevious);
  }

  updateItemsPerPage(val: number) {
    if (this.itemsPerPage === val) return;
    this.pageInfo.startCursor = '';
    this.pageInfo.endCursor = '';
    this.setItemsPerPage(val);
    this.$emit('do-search', false);
  }

  get computedHeaders(): ComputedHeaders[] {
    const bp = this.$vuetify.breakpoint;
    return this.headers.filter(h => {
      return !h.hide || bp[h.hide as keyof Breakpoint];
    });
  }

  openDetail(id: string) {
    this.$router
      .push({
        name: ROUTER.WORKFLOW_LIST,
        params: { integrationId: id },
      })
      .catch(err => console.error(err));

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('open_integration_from_search_table', {
      event_category: 'integration',
      event_label: 'open integration from search result table',
      value: id,
    });
  }

  openProduct(id: string, product: string) {
    this.$router
      .push({
        name: ROUTER.WORKFLOW_DETAILS,
        params: {
          integrationId: id.toString(),
          product: product.toString(),
        },
      })
      .catch(err => console.error(err));

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('open_workflow_from_search_table', {
      event_category: 'integration_workflow',
      event_label: 'open workflow from search result table',
      value: `${id}_${product}`,
    });
  }

  humanize(workflows: Workflow[]): ReducedWorkflow[] {
    // removes duplicate products from recertification workflows
    // and allows for proper reusuable naming conventions/references
    // for colors/i18n
    const unique = workflows
      .slice()
      .sort((a, b) => a.id - b.id)
      .reduce((acc: ReducedWorkflow[], cur) => {
        const product = this.$t(`products.${cur.product}`);
        const index = acc.findIndex(c => c.product === product);

        if (index > -1) {
          acc[index].state = cur.smState;
        } else {
          const obj = {
            product: product, // for css display (dcr_static -> DCR Static)
            codeProduct: cur.product, // for query param value in link (integration/:integration_id/:product)
            state: cur.smState,
          };
          acc.push(obj);
        }

        return acc;
      }, []);
    return unique;
  }
}
